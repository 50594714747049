import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import ExchangeRatesInfo from "../../model/ExchangeRatesInfo";
import {useSnackbar} from "notistack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import {useEffect, useState} from "react";
import {dashboardDate} from "../../utils/dateFormats";
import {deleteExchangeRateAdminApiRoute} from "../../api/routes/paymentRoutes";

interface ExRateDeleteDialogProps {
    exRateToDelete?:ExchangeRatesInfo,
    onWantsToClose: (exRateWasDeleted: boolean) => void;
}

const ExRateDeleteDialog = ({
    exRateToDelete,
    onWantsToClose
}: ExRateDeleteDialogProps) => {

    const { enqueueSnackbar } = useSnackbar();

    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const [copiedExRateName, setCopiedExRateName] = useState<string | undefined>(undefined);

    useEffect(() => {
        if (exRateToDelete) {
            setCopiedExRateName(` ${exRateToDelete!.fCurr }  ${exRateToDelete!.tCurr } at ${dashboardDate(exRateToDelete!.valueAt)} `);
        }
    }, [exRateToDelete]);

    const deleteExRate = async () => {
        setIsSubmitting(true);

        try {
            await deleteExchangeRateAdminApiRoute(exRateToDelete!.id);
            enqueueSnackbar(
                `Exchange rate "${copiedExRateName}" was successfully deleted`,
                {
                    variant: "success",
                }
            );
            onWantsToClose(true);
        } catch (error: any) {
            enqueueSnackbar(error.message, {
                variant: "error",
            });
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <Dialog open={!!exRateToDelete} maxWidth="md" disableRestoreFocus>
            <DialogTitle>Are you sure?</DialogTitle>
            <DialogContent>
                <Typography variant={"body2"}>
                    Do you really want to delete the exchange rate "
                    {copiedExRateName}"? User balances will not be updates.
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button
                    variant={"outlined"}
                    disabled={isSubmitting}
                    onClick={() => onWantsToClose(false)}
                >
                    Cancel
                </Button>
                <LoadingButton
                    variant={"contained"}
                    loading={isSubmitting}
                    onClick={deleteExRate}
                >
                    Delete the Exchange Rate
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};

export default ExRateDeleteDialog;