import AcquiringKeyWalletInfo, {AcquiringKeyCard, AcquiringKeyMobile} from "../../model/AcquiringKeyWalletInfo";
import {Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import React, {useRef, useState} from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import {formatCurrencyCodeSign} from "../shared/CurrencySelect";
import {UpdateWallet, updateWalletAdminApiRoute} from "../../api/routes/paymentRoutes";
import {useSnackbar} from "notistack";

interface WalletEditDialogProps {
    walletInfoCard?: AcquiringKeyCard;
    walletInfoMobile?: AcquiringKeyMobile;
    onWantsToClose: () => void;
    currency: string;
    walletType: string;
    onUpdate: (walletInfo: AcquiringKeyWalletInfo) => void;
}

const WalletEditDialog = ({
                              walletInfoCard,
                              walletInfoMobile,
                              onWantsToClose,
                              currency,
                              walletType,
                              onUpdate
                          }: WalletEditDialogProps) => {

    const [isUpdating, setIsUpdating] = useState<boolean>(false);

    const paymentURLInput = useRef<HTMLInputElement>(null);
    const apiKeyInput = useRef<HTMLInputElement>(null);
    const serviceIDInput = useRef<HTMLInputElement>(null);

    const {enqueueSnackbar} = useSnackbar();

    const updateWalletInfo = async () => {
        setIsUpdating(true);
        try {
            const request: UpdateWallet = {
                currency: currency,
                paymentURL: paymentURLInput.current!.value,
                walletType: walletType,
                apiKey: apiKeyInput.current?.value,
                serviceID: serviceIDInput.current?.value,
            };
            let updatedRate = await updateWalletAdminApiRoute(request);
            onUpdate(updatedRate);
        } catch (error: any) {
            if (error.status === 422) {
                enqueueSnackbar(JSON.stringify(error.details), {
                    variant: "error",
                });
            } else {
                enqueueSnackbar(error.message, {
                    variant: "error",
                });
            }
        } finally {
            setIsUpdating(false);
            onWantsToClose();
        }
    }


    return (
        <Dialog open={!!walletInfoCard || !!walletInfoMobile} fullWidth maxWidth="md" disableRestoreFocus>
            <DialogTitle>
                Wallet edit dialog, wallet type is {walletType}
            </DialogTitle>
            <DialogContent>
                {walletInfoCard && (
                    <Grid container>
                        <Grid item xs={12} sm={12}>
                            Change wallet options for currency {currency} {formatCurrencyCodeSign(currency)}
                        </Grid>
                        {walletInfoCard.paymentURL && (<Grid item xs={12} sm={12} mt={2}>
                            <TextField
                                sx={{width: "100%"}}
                                id="PaymentUrl"
                                label="Payment URL"
                                defaultValue={walletInfoCard.paymentURL}
                                required
                                inputRef={paymentURLInput}
                            />
                        </Grid>)}
                        {walletInfoCard.apiKey && (<Grid item xs={12} sm={12} mt={2}>
                            <TextField
                                sx={{width: "100%"}}
                                id="ApiKey"
                                label="Wallet API Key"
                                defaultValue={walletInfoCard.apiKey}
                                required
                                inputRef={apiKeyInput}
                            />
                        </Grid>)}
                    </Grid>
                )}
                {walletInfoMobile && (
                    <Grid container>
                        <Grid item xs={12} sm={12}>
                            Change wallet options for currency {currency} {formatCurrencyCodeSign(currency)}
                        </Grid>
                        {walletInfoMobile.paymentURL && (<Grid item xs={12} sm={12} mt={2}><TextField
                            sx={{width: "100%"}}
                            id="PaymentUrl"
                            label="Mobile payment URL"
                            defaultValue={walletInfoMobile.paymentURL}
                            required
                            inputRef={paymentURLInput}
                        /></Grid>)}
                        {walletInfoMobile.serviceID && (<Grid item xs={12} sm={12} mt={2}><TextField
                            sx={{width: "100%"}}
                            id="ServiceID"
                            label="Service ID"
                            defaultValue={walletInfoMobile.serviceID}
                            required
                            inputRef={serviceIDInput}
                        /></Grid>)}
                    </Grid>
                )}
            </DialogContent>
            <DialogActions>
                <Button
                    variant={"outlined"}
                    disabled={isUpdating}
                    onClick={() => onWantsToClose()}
                >
                    Cancel
                </Button>
                <LoadingButton
                    variant={"contained"}
                    loading={isUpdating}
                    onClick={updateWalletInfo}
                >
                    Update
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}

export default WalletEditDialog;