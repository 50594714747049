import ExchangeRatesInfo from "../../model/ExchangeRatesInfo";
import {Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import React, {useRef, useState} from "react";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import {
    CreateExchangeRate,
    createExchangeRateAdminApiRoute,
} from "../../api/routes/paymentRoutes";
import {useSnackbar} from "notistack";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import {APP_DEFAULT_CURRENCY} from "../../config";
import dayjs, {Dayjs} from "dayjs";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {LocalizationProvider} from "@mui/x-date-pickers";
import CurrencySelect, {CurrencyType, getCurrency} from "../shared/CurrencySelect";

interface ExRateAddDialogProps {
    isOpen: boolean;
    onWantsToClose: () => void;
    onCreate: (exRate: ExchangeRatesInfo) => void;
}

const ExRateAddDialog = ({
                             isOpen,
                             onWantsToClose,
                             onCreate
                         }: ExRateAddDialogProps) => {

    const [isCreating, setIsCreating] = useState<boolean>(false);

    const rateInput = useRef<HTMLInputElement>(null);

    const [rateDate, setRateDate] = useState<Dayjs | null>(dayjs());

    const [fCurr, setFCurr] = useState<CurrencyType>(getCurrency(APP_DEFAULT_CURRENCY));

    const [tCurr, setTCurr] = useState<CurrencyType>(getCurrency(APP_DEFAULT_CURRENCY));

    const {enqueueSnackbar} = useSnackbar();


    const createExRate = async () => {
        setIsCreating(true);
        try {
            const request: CreateExchangeRate = {
                rate: rateInput.current!.value,
                tCurr: tCurr!.code,
                fCurr: fCurr!.code,
                valueAt: rateDate? rateDate.toJSON() : dayjs().toJSON(),
            }
            let updatedPayout = await createExchangeRateAdminApiRoute(request)
            onCreate(updatedPayout);
        } catch (error: any) {
            if (error.status === 422) {
                enqueueSnackbar(JSON.stringify(error.details), {
                    variant: "error",
                });
            } else {
                console.log(error);
                enqueueSnackbar(error.message, {
                    variant: "error",
                });
            }
        } finally {
            setIsCreating(false);
            onWantsToClose();
        }
    }

    return (
        <Dialog open={isOpen} fullWidth maxWidth="md" disableRestoreFocus>
            <DialogTitle>
                Create new exchange rate value
            </DialogTitle>
            <DialogContent>
                <Grid container>
                    <Grid item xs={12} sm={12} mt={2}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                inputFormat="DD/MM/YYYY"
                                label="On date"
                                value={rateDate}
                                onChange={(newValue) => {
                                    setRateDate(newValue);
                                }}
                                renderInput={(params) =>
                                    <TextField
                                        sx={{width: "100%"}}
                                        helperText={
                                            "Exchange rate start date"
                                        }
                                        {...params}
                                    />}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} sm={12} mt={2}>
                        <CurrencySelect
                            sx={{width: "100%"}}
                            currValue={fCurr}
                            onFormat={(code, sign) => {
                                setFCurr(getCurrency(code))
                            }}
                            id="currencySelect"
                            label="Choose tips currency"
                            required
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} mt={2}>
                        <CurrencySelect
                            sx={{width: "100%"}}
                            currValue={tCurr}
                            onFormat={(code, sign) => {
                                setTCurr(getCurrency(code))
                            }}
                            id="currencySelect"
                            label="Choose tips currency"
                            required
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} mt={2}>
                        <TextField
                            sx={{width: "100%"}}
                            id="Rate"
                            label="Rate value"
                            defaultValue={"0.00"}
                            required
                            inputRef={rateInput}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    variant={"outlined"}
                    disabled={isCreating}
                    onClick={() => onWantsToClose()}
                >
                    Cancel
                </Button>
                <LoadingButton
                    variant={"contained"}
                    loading={isCreating}
                    onClick={createExRate}
                >
                    Create
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}

export default ExRateAddDialog;