import ExchangeRatesInfo from "../../model/ExchangeRatesInfo";
import {Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import React, {useRef, useState} from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import {dashboardDate} from "../../utils/dateFormats";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";
import {UpdateExchangeRate, updateExchangeRateAdminApiRoute} from "../../api/routes/paymentRoutes";
import {useSnackbar} from "notistack";

interface ExRateEditDialogProps {
    exRate?: ExchangeRatesInfo;
    onWantsToClose: () => void;
    onUpdate: (exRate: ExchangeRatesInfo) => void;
}

const ExRateEditDialog = ({
                              exRate,
                              onWantsToClose,
                              onUpdate,
                          }: ExRateEditDialogProps) => {

    const [isUpdating, setIsUpdating] = useState<boolean>(false);

    const rateInput = useRef<HTMLInputElement>(null);

    const {enqueueSnackbar} = useSnackbar();

    const updateExRate = async () => {
        setIsUpdating(true);
        try {
            const request: UpdateExchangeRate = {
                rate: rateInput.current!.value,
            }
            let updatedRate = await updateExchangeRateAdminApiRoute(exRate!.id, request)
            onUpdate(updatedRate);
        } catch (error: any) {
            if (error.status === 422) {
                enqueueSnackbar(JSON.stringify(error.details), {
                    variant: "error",
                });
            } else {
                enqueueSnackbar(error.message, {
                    variant: "error",
                });
            }
        } finally {
            setIsUpdating(false);
            onWantsToClose();
        }
    }


    return (
        <Dialog open={!!exRate} fullWidth maxWidth="md" disableRestoreFocus>
            <DialogTitle>
                Exchange rate edit dialog
            </DialogTitle>
            <DialogContent>
                {exRate && (
                    <Grid container>
                        <Grid item xs={12} sm={12}>
                            Exchange rate from {exRate.fCurr} to {exRate.tCurr} on {dashboardDate(exRate.valueAt)}
                        </Grid>
                        <Grid item xs={12} sm={12} mt={2}>
                            <TextField
                                sx={{width: "100%"}}
                                id="Rate"
                                label="Rate value"
                                defaultValue={exRate.rate}
                                required
                                inputRef={rateInput}
                            />
                        </Grid>
                    </Grid>
                )}
            </DialogContent>
            <DialogActions>
                <Button
                    variant={"outlined"}
                    disabled={isUpdating}
                    onClick={() => onWantsToClose()}
                >
                    Cancel
                </Button>
                <LoadingButton
                    variant={"contained"}
                    loading={isUpdating}
                    onClick={updateExRate}
                >
                    Update
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}

export default ExRateEditDialog;