import {deleteApiRequest, getApiRequest, postApiRequest, putApiRequest} from "../index";
import Payment, {PaymentCallback} from "../../model/Payment";
import {Dayjs} from "dayjs";
import ExchangeRatesInfo from "../../model/ExchangeRatesInfo";
import PaymentStatusInfo from "../../model/PaymentStatusInfo";
import AcquiringKeyWalletInfo from "../../model/AcquiringKeyWalletInfo";
import PaymentLinkInfo from "../../model/PaymentLinkInfo";

export const listPaymentsApiRoute = async (userID: string) => {
    return (await getApiRequest(`admin/payments/${userID}`)) as Payment[];
};

export const listAllPaymentsApiRoute = async (fromDate: Dayjs, toDate: Dayjs, paymentStatus: string[]) => {
    return (await getApiRequest(`admin/payments?from=${fromDate.toJSON()}&to=${toDate.toJSON()}&paymentStatus=${paymentStatus.toString()}`)) as PaymentStatusInfo[];
};

export const getPaymentStatusApiRoute = async (paymentID: string) => {
    return (await getApiRequest(`admin/unknown/payments/${paymentID}/check`)) as PaymentStatusInfo
}

export interface UpdatePaymentStatus {
    status: string;
    description?: string;
}

export const updatePaymentStatusRoute = async (paymentID: string, request: UpdatePaymentStatus) => {
    return (await putApiRequest(`admin/payments/${paymentID}/status`, request)) as Payment;
};

export const getPaymentStatusForAdminApiRoute = async (paymentID: string) => {
    return (await getApiRequest(`admin/payments/${paymentID}/status`)) as PaymentStatusInfo;
};

export const listPaymentCallbacksAdminApiRoute = async (paymentID: string) => {
    return (await getApiRequest(`admin/payments/${paymentID}/callbacks`)) as PaymentCallback[];
};

export const listExchangeRates = async (onDate: Dayjs, fCurr?: string, tCurr?: string) => {
    let currencyFilter = (fCurr === undefined ? '' : '&fCurr=' + fCurr) + (tCurr === undefined ? '' : '&tCurr=' + tCurr)
    return (await getApiRequest(`admin/ex-rates?onDate=${onDate.toJSON()}${currencyFilter}`)) as ExchangeRatesInfo[];
};


export const listWallets = async () => {
    return (await getApiRequest(`admin/wallets`)) as AcquiringKeyWalletInfo[];
};

export const refreshWalletsCache = async () => {
    return (await postApiRequest(`admin/wallets/refresh`, null)) as AcquiringKeyWalletInfo[];
}

export interface UpdateWallet {
    currency: string;
    paymentURL: string;
    walletType: string;
    apiKey?: string;
    serviceID?: string;
}

export const updateWalletAdminApiRoute = async (request :UpdateWallet) => {
    return (await putApiRequest(`admin/wallets`, request)) as AcquiringKeyWalletInfo;
}

export const listUserPaymentLinksApiRoute = async (fromDate: Dayjs, toDate: Dayjs, userID: string) => {
    return (await getApiRequest(`/admin/users/${userID}/payment-links?from=${fromDate.toJSON()}&to=${toDate.toJSON()}`)) as PaymentLinkInfo[];
    // return (await new Promise(resolve => setTimeout(() => {
    //         let result: PaymentLinkInfo[] = [
    //             {id: "link_5ZLNPdep2nDMK50jMorJaWgQ", description: "MyBank QR Code", qrCodeFileName: "https://itips-userdata.s3.eu-central-1.amazonaws.com/354eb09a93be44be90c3d9bf7fbdbd79", paymentLocalAmount:"10.11", paymentLocalCurrency: "EUR", paymentBalanceAmount:"121.12", paymentBalanceCurrency:"TRY"},
    //             {id: "link_LAVeYPn3Kp0lQaB49w2gyXm6", description: "The default QR code", qrCodeFileName: "https://itips-userdata.s3.eu-central-1.amazonaws.com/c0de572bde1e419788367b14d295f6ec-code.png", paymentLocalAmount:"12.11", paymentLocalCurrency: "EUR" , paymentBalanceAmount:"118.12", paymentBalanceCurrency:"TRY"}
    //         ]
    //         resolve(result);
    //     },1000))
    // ) as PaymentLinkInfo[];
};

export const listEstablishmentPaymentLinksApiRoute = async (fromDate: Dayjs, toDate: Dayjs, establishmentID: string) => {
    return (await getApiRequest(`/admin/establishments/${establishmentID}/payment-links?from=${fromDate.toJSON()}&to=${toDate.toJSON()}`)) as PaymentLinkInfo[];
}

export interface UpdateExchangeRate {
    rate: string;
}

export const updateExchangeRateAdminApiRoute = async (exchangeRateID: string, request: UpdateExchangeRate) => {
    return (await putApiRequest(`admin/ex-rates/${exchangeRateID}`, request)) as ExchangeRatesInfo;
};

export interface CreateExchangeRate {
    valueAt: string;
    rate: string;
    fCurr: string;
    tCurr: string;
}

export const createExchangeRateAdminApiRoute = async (request: CreateExchangeRate) => {
    return (await postApiRequest(`admin/ex-rates`, request)) as ExchangeRatesInfo;
}

export const deleteExchangeRateAdminApiRoute = async (exRateId: string) => {
    return await deleteApiRequest(`admin/ex-rates/${exRateId}`);
}